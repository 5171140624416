<template>
  <b-card>
    <div class="d-sm-flex justify-content-between align-items-center mb-3">
      <h4 class="card-title mb-50 mb-sm-0">部门评估进展</h4>
    </div>

    <!-- chart -->
    <vue-apex-charts
      id="revenue-report-chart"
      type="bar"
      height="200"
      :options="revenueReport.chartOptions"
      :series="revenueReport.series"
    />

    
  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      revenueReport: {
        series: [
          {
            name: "进度",
            data: [95, 80, 25, 50],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ["部门1", "部门2", "部门3", "部门4"],
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.86rem",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.danger, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.86rem",
              },
            },
          },
        },
      },
    };
  },
  created() {
    //this.$http.get('/card/card-analytics/revenue-report').then(res => { this.revenue_report = res.data })
  },
};
</script>