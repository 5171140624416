<template>
  <div>
    <b-row class="match-height">

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="danger"
          icon="CpuIcon"
          statistic="120"
          statistic-title="企业数量"
        />
      </b-col>

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          icon="MapIcon"
          statistic="150"
          statistic-title="数据地图"
          color="info"
        />
      </b-col>

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          icon="BookIcon"
          statistic="300"
          statistic-title="知识库"
          
        />
      </b-col>

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="warning"
          icon="MessageSquareIcon"
          statistic="120"
          statistic-title="评估产品"
        />
      </b-col>

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="danger"
          icon="UserCheckIcon"
          statistic="3502"
          statistic-title="同意数量"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="5">
        <CompanyProgress/>
        <DataMapOverview/>
      </b-col>

      <b-col cols="5">
        <DepartProgress/>
        <PIAOverview/>
      </b-col>    
    </b-row>  
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import CompanyProgress from './Home/CompanyProgress.vue'
import DepartProgress from './Home/DepartProgress.vue'
import DataMapOverview from './Home/DataMapOverview.vue'
import PIAOverview from './Home/PIAOverview.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
    CompanyProgress,
    DataMapOverview,
    DepartProgress,
    PIAOverview,
  },

  data() {
    return {};
  },
};
</script>